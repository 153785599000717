// constants/index.js
import {facebook, instagram, linkedin, twitter, send, shield, star, discount, neymar, mbappe, jettblue, dustin, csgosmoke, curry, counterstrike, esports1, esports2, mcgregor, allchars, bluelogo, stockanimation, csgogeneric, phoenixvalorant } from "../assets";

export const SITE_NAME = "StatSeeker";
export const BASE_API_URL = "https://api.example.com";

//Navbar////////////////////////////////
export const navLinks = [
  {
    id: 1,
    title: 'Home',
    to: '/',
    submenu: null,
    clickable: true  // This is clickable (default behavior)
  },
  {
    id: 2,
    title: 'Sports',
    to: '/sports',
    submenu: [
      {
        id: 1,
        title: 'Valorant',
        to: '/sports/valorant'
      }
      // Add more submenu items as needed
    ],
    clickable: false  // This is NOT clickable
  },
];


//HOMEPAGE////////////////////////////////
  export const heroImages = [
    counterstrike,
    curry,
    mbappe,
    mcgregor,
    jettblue,
    csgogeneric,
    neymar,
    esports1,
  ];

  export const heroConstants = {
    id: "hero-section",
    discountImage: discount,
    discountAltText: "discount",
    discountText: {
      part1: "Empowering",
      part2: "Your",
      part3: "Bets,",
      part4: "One Insight at a time"
    },
    title: {
      line1: "Bet Smart",
      line2: "Win Big",
      line3: "Repeat."
    }
  };

  export const whyChooseUsConstants = {
    title: "Why Choose Us?",
    subtitle: "Elevate your sports betting experience with us! Our expertise lies in providing you with in-depth insights into the performance of players in both esports and physical sports. Spend less time researching and more time placing winning bets."
  };
  
  export const features = [
    {
      id: "feature-1",
      icon: star,
      title: "Data-Driven Insights",
      content:
        "Uncover key player statistics from the last 10 games, empowering your bets with informed decisions.",
    },
    {
      id: "feature-2",
      icon: shield,
      title: "Easy Navigation",
      content:
        "Navigate effortlessly through our intuitive interface to access player histories and trends.",
    },
    {
      id: "feature-3",
      icon: send,
      title: "Save Time, Bet Wisely",
      content:
        "We do the groundwork so you can concentrate on strategic betting, maximizing your winning potential.",
    },
  ];

  export const ctaConstants = {
    id: "cta-section",
    title: "Let’s try our service now!",
    content: "Everything you need to accept card payments and grow your business anywhere on the planet."
  };
  

//Footer////////////////////////////////
export const socialMedia = [
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/",
  },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/",
  },
  {
    id: "social-media-3",
    icon: twitter,
    link: "https://www.twitter.com/",
  },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://www.linkedin.com/",
  },
];

// ValorantFAQ Constants
export const valorantFAQContent = {
  title: "Frequently Asked Questions",
  faqs: [
    {
      id: "faq-1",
      question: "When do you guys update these stats?",
      answer: "We update our stats every Monday."
    },
    {
      id: "faq-2",
      question: "How accurate are the stats?",
      answer: "Our stats are 99.9% accurate thanks to our dedicated team."
    },
    {
      id: "faq-3",
      question: "Where do you source your data?",
      answer: "We source our data from multiple reputable sources and databases."
    }
  ],
  lastUpdated: "Data Last Updated: (Date) (Time) (TimeZone)"
};

export const featureCardsInfo = [
  {
    id: 1,
    title: "Data-Driven Insights",
    content: "Uncover key player statistics from the last 10 games, empowering your bets with informed decisions.",
  },
  {
    id: 2,
    title: "Easy Navigation",
    content: "Navigate effortlessly through our intuitive interface to access player histories and trends.",
  },
  {
    id: 3,
    title: "Save Time, Bet Wisely",
    content: "We do the groundwork so you can concentrate on strategic betting, maximizing your winning potential.",
  },

  
];

export const billingDetails = {
  monthlyPlan: {
    title: "Monthly",
    description: "3 Day Free trial | No CC!",
    features: [
      "Last 10 Games Analysis",
      "Averaged Performance Metrics",
      "Sports Book Integration",
      "Same Day Updates",
      "Search Function"
    ]
  },
};