import React, { useContext, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

// Create the AuthContext
export const AuthContext = React.createContext();

// Create the AuthProvider component
export const AuthProvider = ({ children }) => {
  const {
    isAuthenticated,
    user,
    loginWithRedirect,
    logout
  } = useAuth0();

  // The value that will be provided to components
  const contextValue = {
    isAuthenticated,
    user,
    loginUser: loginWithRedirect,
    logoutUser: () => logout({ returnTo: window.location.origin })
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

// Create the AuthButton component
export const AuthButton = () => {
  const { isAuthenticated, loginUser, logoutUser } = useContext(AuthContext);

  return (
    <div>
      {isAuthenticated ? (
        <button onClick={logoutUser}>Logout</button>
      ) : (
        <button onClick={() => loginUser({name: "Demo User"})}>Login</button>
      )}
    </div>
  );
}
