import React from "react";
import { heroImages } from "../../constants";
import Style from  "../../styles/SpinningImages.css";

const SpinningImages = () => {
  const isTablet = window.innerWidth >= 768 && window.innerWidth <= 1024;

  return (
    <div className={`box-container ${isTablet ? 'tablet' : ''}`}>
      <div className="box">
        {heroImages.map((src, index) => (
          <span key={index} style={{ "--i": index + 1 }}>
            <img src={src} alt="" className="spinning-image" />
          </span>
        ))}
      </div>
    </div>
  );
};


export default SpinningImages;
