import React, { useState } from 'react';
import ValorantBorder from '../../components/ValorantBorder';
import ValorantHero from './ValorantHero';
import ValorantFAQ from './ValorantFAQ';
import ValorantSearchTable from './ValorantSearchTable';

const Valorant = () => {
    const [selectedPlayer, setSelectedPlayer] = useState(null);

    return (
        <div className="bg-primary pt-10 min-h-screen relative">
            <ValorantHero />
            <ValorantFAQ />
            <ValorantSearchTable />
        </div>
    );
}

export default Valorant;
