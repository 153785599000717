import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "../../components/LoginButton";
import { billingDetails } from "../../constants"; // Import your billing details

const Billing = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <section className="flex flex-col items-center space-y-10 bg-primary">
      
      {/* Boxes container */}
      <div className="flex space-x-8 items-center"> {/* Vertically centered boxes */}
        
    {/* First (Smaller) Box */}
    <div className="rectangle-payment flex flex-col items-center justify-center shadow-md text-white font-poppins">
      <div className="rectangle-payment-fixed-text">
        <h2 className="text-3xl mb-2">{billingDetails.monthlyPlan.title}</h2>
        <p className="text-lg pb-4">{billingDetails.monthlyPlan.description}</p>
      </div>
          
          {/* Only show the LoginButton if the user is not authenticated */}
          {!isAuthenticated && 
            <LoginButton 
              context="billing" // Pass the context as "billing"
              className={`w-full max-w-[90%] py-2 px-2 sm:px-3 md:px-5 font-medium text-[15px] sm:text-[16px] text-white bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 rounded-[10px] outline-none transition-all duration-300`}
            />
          }
        </div>

        {/* Second (Larger) Box */}
        <div 
          className="w-80 h-96 rectangle-benefits flex flex-col items-center justify-start shadow-md text-white font-poppins"
          style={{ paddingTop: '35px', paddingBottom: '24px', paddingLeft: '24px', paddingRight: '24px' }} // Adjust top padding to bring content closer to top
        >
          <ul className="list-none space-y-6 text-lg">
            {billingDetails.monthlyPlan.features.map((feature, index) => (
              <li key={index} className="flex items-center space-x-2">
                <span className="text-2xl">✓</span>
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>

    </section>
  );
};

export default Billing;
