import React, { useRef } from 'react';
import styles, { layout } from '../../styles/style.js';
import { featureCardsInfo } from '../../constants'; // Import the new constant
import useOnScreen from '../../utils/isOnScreen'; // Adjust the path based on where you save the hook

function Info() {
    const ref = useRef(null);
    const isVisible = useOnScreen(ref);
    const animationClass = isVisible ? 'rectangle-box-animate' : '';

    return (
        <div ref={ref} className={`${styles.boxWidth} ${styles.flexCenter} flex-row mb-20 space-x-8`}>
            {featureCardsInfo.map((card) => (
                <div key={card.id} className={`${styles.featureCard} ${layout.sectionImg} rounded-lg p-4 w-1/6 h-56 flex flex-col justify-center items-center rectangle-box ${animationClass}`}>
                    {/* Heading with reduced margin bottom */}
                    <h2 className={`${styles.heading2} text-center`} style={{ fontSize: '22px' }}>{card.title}</h2>
                    {/* Paragraph text with reduced margin top */}
                    <p className={`${styles.paragraph} text-sm text-center mb-10`}>{card.content}</p>
                </div>
            ))}
        </div>
    );  
}



export default Info;
