import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from 'react';
import Loading from "../components/Loading";

function ProtectedRoute({ children }) {
  const { isAuthenticated, loginWithRedirect, isLoading: auth0Loading } = useAuth0();
  const [isRedirecting, setIsRedirecting] = useState(false);

  useEffect(() => {
    if (!isAuthenticated && !auth0Loading) {
        setIsRedirecting(true);
        // Introduce a delay before redirecting to the login page
        setTimeout(() => {
            loginWithRedirect().then(() => {
                setIsRedirecting(false);
            });
        }, 2000); // 2-second delay
    }
  }, [isAuthenticated, loginWithRedirect, auth0Loading]);

  if (auth0Loading || isRedirecting) {
    return <Loading />; // Display the Loading component
  }

  return children;
}

export default ProtectedRoute;
