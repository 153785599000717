import React, { useRef } from 'react';
import { whyChooseUsConstants } from "../../constants";
import styles, { layout } from "../../styles/style";
import useOnScreen from '../../utils/isOnScreen'; // Adjust the path based on where you save the hook

const WhyChooseUs = () => {
    const ref = useRef(null);
    const isVisible = useOnScreen(ref);

    return (
        <section ref={ref} id="features" className={`${layout.section} ${styles.flexCenter} ${isVisible ? 'slide-up mb-20' : ''}`}>
            <div 
                style={{
                    position: 'relative',
                    border: '2px solid transparent',
                    borderTopColor: '#6C6D6F',
                    background: 'transparent',
                    borderRadius: '20px',
                    padding: '20px 40px',
                    width: '800px',
                    height: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '0 auto'  // Center the container on the webpage
                }}
            >
                <div className={layout.sectionInfo}>
                    <h2 className={`${styles.heading2} text-center`} style={{fontSize: '70px', textAlign: 'center'}}>
                        <span>Why </span>
                        <span style={{color: '#258BEA'}}>Choose</span>
                        <span> Us?</span>
                    </h2>
                    <p className={`${styles.paragraph} text-center`} style={{textAlign: 'center', maxWidth: '74%', margin: 'auto'}}>  {/* Centered the paragraph */}
                        {whyChooseUsConstants.subtitle}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default WhyChooseUs;
