import React, { useRef } from 'react';
import { CSGOLogo, Dota2Logo, ValorantLogo, lolLogo } from '../../assets';
import useOnScreen from '../../utils/isOnScreen'; // Adjust the path based on where you save the hook

const Logos = () => {
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);
  
  const logos = [CSGOLogo, Dota2Logo, lolLogo, ValorantLogo];

  return (
    <div className="container mx-auto mb-20 mt-10">
      <div className="flex justify-between items-center flex-wrap p-8">
        {logos.map((logo, index) => (
          <img 
            key={index} 
            src={logo} 
            alt={`Logo ${index + 1}`} 
            className="w-32 h-auto mb-4 md:mb-0 scale-on-hover" // Adjusted width to w-36
          />
        ))}
      </div>
    </div>
  );
}

export default Logos;
