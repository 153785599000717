import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { redirectToStripePortal } from '../utils/stripeutils';
import styles from '../styles/style';
import { logo } from '../assets';

const Navbar = () => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  const handleProfileClick = () => {
    if (isAuthenticated && user) {
      redirectToStripePortal(user);
    }
  };

  return (
    <nav className="flex px-8 bg-primary justify-between items-center mt-6">
      <div className="logo">
        <img src={logo} alt="Your Logo" className="w-60 mt-1" />
      </div>

      <div className={`py-1 px-3 mt-4 rounded-full bg-gray-0 border border-gradient-to-r from-gray-600 via-gray-500 to-gray-600 w-auto ${styles.pillGrow} whitespace-nowrap`}>
        <div className="flex items-center rounded-full px-2 py-1.5">
          <span className="text-white text-sm px-2 py-1">Sports</span>

          {isAuthenticated ? (
            <>
              <button onClick={handleProfileClick} className="text-white text-sm px-2 py-1 ml-5 glow-on-hover">
                Hi, {user.nickname}
              </button>
              <div className="border border-gradient-to-r from-gray-600 via-gray-500 to-gray-600 rounded-full px-1 py-0.5 ml-5">
                <button onClick={() => logout({ returnTo: window.location.origin })} className="text-white text-sm px-2.5 py-0.5 glow-on-hover">
                  Logout
                </button>
              </div>
            </>
          ) : (
            <div className="border border-gradient-to-r from-gray-600 via-gray-500 to-gray-600 rounded-full px-1 py-0.5 ml-5">
              <button onClick={loginWithRedirect} className="text-white text-sm px-2.5 py-0.5 glow-on-hover">
                Login
              </button>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
