import React, { useEffect, useState, Suspense} from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { SubscriptionProvider } from './contexts/SubscriptionContext/Subscription';
import Paywall from './components/Paywall';
import ProtectedRoute from './utils/ProtectedRoute';

import { AuthProvider } from './contexts/AuthContext/Auth';
import Loading from './components/Loading';
import Navbar from './components/Navbar';
import Home from './views/Home/Home';
import Valorant from './views/Sports/Valorant';
import Footer from './components/Footer';
import styles from './styles/style';

function MainContent() {
  const {isAuthenticated, getIdTokenClaims } = useAuth0();
  const [isSubscriber, setIsSubscriber] = useState(null);
  const [isLoading, setIsLoading] = useState(true);  // New state for loading

  useEffect(() => {
    if (isAuthenticated) {
      getIdTokenClaims().then(claims => {
        const roles = claims["https://statseeker.io/roles"] || [];
        const userIsSubscriber = roles.includes("subscriber");
        setIsSubscriber(userIsSubscriber);

        setIsLoading(false);  // Set isLoading to false after fetching data
      });
    } else {
      setIsSubscriber(false);
      setIsLoading(false);  // Set isLoading to false if not authenticated
    }
  }, [isAuthenticated, getIdTokenClaims]);

  if (isLoading) {
    return <Loading />;
  }

  if (isSubscriber === null) {
    return <Loading />; 
  }

  return (
    <div>
      <div className="card mx-auto max-w-screen-xl">
          <Navbar /> 
      </div>
      
      {/* Main content with padding/margin on both sides */}
      <div className="card mx-auto max-w-screen-xl px-6">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sports/valorant" element={
            <ProtectedRoute>
              <Paywall><Valorant /></Paywall>
            </ProtectedRoute>
          } />
          {/* Add more routes as necessary */}
        </Routes>
      </div>
      
      <div className="bg-primary w-full overflow-hidden">
        <div className={styles.paddingX}>
          <Footer />
        </div>
      </div>
    </div>
  );
}

function App() {
  const domain = "dev-mb6yjgc8jrsrfcnc.us.auth0.com";
  const clientId = "OHOb1Q8K3WewZXypUZH53nKw5HTkUWOG";

  return (
    <Auth0Provider domain={domain} clientId={clientId} redirectUri={window.location.origin}>
      <AuthProvider>
        <SubscriptionProvider>
          <Router>
            <Suspense fallback={<Loading />}>
              <MainContent />
            </Suspense>
          </Router>
        </SubscriptionProvider>
      </AuthProvider>
    </Auth0Provider>
  );
}

export default App;
