import React from 'react';
import styles from '../styles/style';

function Loading() {
    return (
        <div className={`${styles.flexCenter} h-screen`} style={{ backgroundColor: '#000000' }}>
            <div className="text-center">
                <div className="loader animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary"></div>
                <p className={`${styles.paragraph} mt-4`}>Redirecting...</p>
            </div>
        </div>
    );
}

export default Loading;
