import React from 'react';

const ValorantSearchTable = () => {
    return (
        <div className="flex justify-center" style={{ paddingTop: '100px' }}> {/* Adjust the padding value as needed */}
            <div className="valorant-search-box h-96 w-4/5">
                {/* Content of the Valorant Search Table goes here */}
            </div>
        </div>
    );
}

export default ValorantSearchTable;
