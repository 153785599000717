import React from "react";

const Button = ({ styles }) => {
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <button
      type="button"
      className={`py-2 px-8 font-poppins font-bold text-[30px] text-primary bg-blue-500 rounded-full outline-none border-2 border-white ${styles}`}
      style={{ backgroundColor: "#258BEA", color: "#000000" }}
      onClick={scrollToBottom} // Use the scrollToBottom function
    >
      Pricing
    </button>
  );
};

export default Button;
