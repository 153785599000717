import React, { createContext, useState, useContext } from 'react';

const SubscriptionContext = createContext();

export const useSubscription = () => {
  return useContext(SubscriptionContext);
};

export const SubscriptionProvider = ({ children }) => {
    const [isSubscriber, setIsSubscriber] = useState(false);
    console.log("SubscriptionProvider isSubscriber value: ", isSubscriber);

  return (
    <SubscriptionContext.Provider value={{ isSubscriber, setIsSubscriber }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
