import React, { useState } from 'react';
import styles from '../../styles/style';
import { jettvalorant } from '../../assets';

const Valorant = () => {

    return (
        <div className="bg-primary pt-10">
{/* Flex container for Valorant Matches Table and Image */}
            <div className={`${styles.paddingX} ${styles.flexCenter} xl:flex-row flex-col`}>
                <div className={`${styles.boxWidth} flex xl:flex-row flex-col justify-between items-flex-start`}>
                    <div className="valorant-matches w-full xl:w-3/6 pr-4 mb-4 xl:mb-0">
                        {/* Title for the Valorant Matches Table */}
                        <h2 className="text-white mb-4 text-center text-2xl">
                            Upcoming <span style={{color: '#b7b1FF'}}>Matches</span>
                        </h2>

                        {/* Placeholder for ValorantMatchesTable component */}
                        <div className="rectangle-box h-96 flex items-center justify-center">
                            <span>ValorantMatchesTable Placeholder</span>
                        </div>
                    </div>
                    <div className="image-placeholder w-full xl:w-2/5 pl-4">
                        {/* Displaying the jettvalorant image */}
                        <img src={jettvalorant} alt="Jett Valorant" className="h-100 w-full object-cover" />
                    </div>
                </div>
            </div>

            {/* Centering Container for Third Box */}
            <div className="flex justify-center">
                {/* Third Box (Valorant Divider) */}
                <div className="w-[670px] h-13 mt-10 valorant-divider flex items-center justify-center p-6 shadow-md text-white font-poppins">
                    {/* Content for the third box can go here */}
                </div>
            </div>
        </div>
    );
};

export default Valorant;
