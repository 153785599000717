import React from "react";
import Hero from "./Hero";
import SpinningImages from "./SpinningImages";
import WhyChooseUs from "./WhyChooseUs";
import Billing from "./Billing";
import styles from "../../styles/style";
import Info from "./Info";
import Logos from "./Logos";
import Example from "./Example";

const Home = () => (
  <div className="bg-primary w-full overflow-hidden">
    <div className={`bg-primary ${styles.flexStart} md:flex-row flex-col mb-8`}> 
      <div className={`${styles.heroWidth} flex items-center justify-start md:flex-row flex-col mx-auto ml-[-35px] mb-6`}>
        <div className="flex-none mt-10 mb-6">
          <Hero />
        </div>
        <div className="flex-grow flex justify-center mt-10 mb-6">
          <SpinningImages />
        </div>
      </div>
    </div>
    <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter} mb-8`}>
      <div className={`${styles.boxWidth}`}>
        <Logos/>
        <Info />
        <WhyChooseUs />
        <Example />
        <Billing />
      </div>
    </div>
  </div>
);

export default Home;

