import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = ({ context }) => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect({ screen_hint: 'signup' });
  };

  if (isAuthenticated) {
    return (
      <>
        <span className="font-poppins font-normal cursor-pointer text-[14px] mr-4">
          Hi, {user.nickname}
        </span>
        <button 
          className="py-4 px-6 font-poppins font-medium text-[18px] text-primary bg-blue-gradient rounded-full outline-none"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          Logout
        </button>
      </>
    );
  } else {
    // Render "Get Started" button only if context is 'billing'
    if (context === 'billing') {
      return (
        <button 
          className="py-4 px-6 font-poppins font-medium text-[18px] text-black bg-[#258bea] rounded-full border-2 border-white outline-none"
          onClick={handleLogin}
        >
          Get Started
        </button>
      );
    }
    // Otherwise, render a standard "Start Trial" button for the navbar
    return (
      <button 
        className="py-4 px-6 font-poppins font-medium text-[18px] text-primary bg-blue-gradient rounded-full outline-none"
        onClick={handleLogin}
      >
        Start Trial
      </button>
    );
  }
};

export default LoginButton;
