import React, { useState, useEffect, useRef } from "react";
import styles from "../../styles/style";
import { TypeAnimation } from 'react-type-animation';
import useOnScreen from '../../utils/isOnScreen'; // Adjust the path based on where you save the hook
import { heroConstants } from "../../constants";
import Button from "../../components/Button";

function Hero() {
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);
  
  const [showFirstLine, setShowFirstLine] = useState(true);
  const [showSecondLine, setShowSecondLine] = useState(false);
  const [showThirdLine, setShowThirdLine] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowSecondLine(true);
    }, 500);

    setTimeout(() => {
      setShowThirdLine(true);
    }, 1000);
  }, []);

  return (
    <div className={`${styles.heroWidth} ${styles.flexCenter} ${styles.padding} fade-in mb-20 md:mr-25 lg:mr-0`} style={{ minHeight: '180px' }}>
      <div className={`flex flex-col items-center text-center md:text-left lg:text-center font-poppins font-semibold text-[30px] md:text-[40px] lg:text-[60px] leading-[40px] md:leading-[40px] lg:leading-[55px]`}>
        
        <div className={`metal-gradient`}>
          {showFirstLine ? (
            <TypeAnimation
              cursor={false}
              sequence={['Why']}
              wrapper="text"
              className="metal-gradient"
              speed={150}
            />
          ) : (
            <span style={{ opacity: 0 }}>Why</span>
          )}
        </div>

        <div className={`blue-gradient mt-1 md:mt-2 lg:mt-3 whitespace-nowrap`}>
          {showSecondLine ? (
            <TypeAnimation
              cursor={false}
              sequence={['Gamble with']}
              wrapper="text"
              className="blue-gradient"
              speed={150}
            />
          ) : (
            <span style={{ opacity: 0 }}>Gamble with</span>
          )}
        </div>

        <div className={`metal-gradient mt-1 md:mt-2 lg:mt-3`}>
          {showThirdLine ? (
            <TypeAnimation
              cursor={false}
              sequence={['Uncertainty']}
              wrapper="text"
              className="metal-gradient"
              speed={150}
            />
          ) : (
            <span style={{ opacity: 0 }}>Uncertainty</span>
          )}
        </div>

        {/* Pricing Button */}
        <div className="flex justify-center md:justify-start w-full" style={{ marginLeft: '14rem' }}>
          <Button styles="mt-7" />
        </div>


        {/* Grey Box */}
        <div className="flex flex-row items-center py-[4px] px-3 bg-discount-gradient rounded-[10px] mt-7 mb-2 md:justify-start justify-center w-6/8">
          <img src={heroConstants.discountImage} alt={heroConstants.discountAltText} className="w-[28px] h-[28px]" />
          <p className={`${styles.paragraph} text-sm ml-2`}>
            <span className="text-white">{heroConstants.discountText.part1}</span> {heroConstants.discountText.part2}{" "}
            <span className="text-white">{heroConstants.discountText.part3}</span> {heroConstants.discountText.part4}
          </p>
        </div>
      </div>
    </div>

  );
  
}

export default Hero;
