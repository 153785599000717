import React, { useState } from 'react';
import { ValorantLogo, neymar, valbackground, valwallpaper1 } from '../../assets/index.js';

function Example() {
    const [selected, setSelected] = useState("matches");

    return (
        <div className="flex rectangle-example mb-10">
            {/* Left Column */}
            <div className="flex flex-col items-center mt-4 gradient-border-container" style={{ 
                flexBasis: '15%', 
                flexShrink: 0,  // Prevents the column from shrinking
            }}>
                <div className="flex flex-col">
                    <div 
                        className={`text-white hover-effect ${selected === 'matches' ? 'selected-text' : ''} mt-2`} 
                        onClick={() => setSelected('matches')}
                    >
                        Matches
                    </div>
                    <div 
                        className={`text-white hover-effect ${selected === 'players' ? 'selected-text' : ''} mt-2`} 
                        onClick={() => setSelected('players')}
                    >
                        Players
                    </div>
                </div>
            </div>

            {/* Right Column */}
            <div className="flex items-center justify-center w-full p-4">
                {selected === 'matches' && <img src={valbackground} alt="Matches Image" />}
                {selected === 'players' && <img src={valwallpaper1} alt="Players Image" />}
            </div>
        </div>
    );
}

export default Example;
