// Base Styles
const boxWidth = "xl:max-w-[1280px] w-full";
const heroWidth = "xl:max-w-[1380px] w-full"; // Slightly wider than boxWidth

// Typography Styles
const heading2 = "font-poppins font-semibold xs:text-[48px] text-[40px] text-white xs:leading-[76.8px] leading-[66.8px] w-full";
const paragraph = "font-poppins font-normal text-dimWhite text-[18px] leading-[30.8px]";

// Flex Styles
const flexCenter = "flex justify-center items-center";
const flexStart = "flex justify-start items-start"; // Change `justify-center` to `justify-start`

// Padding Styles
const paddingX = "sm:px-16 px-6";
const paddingY = "sm:py-16 py-6";
const padding = `${paddingX} ${paddingY}`;

// Margin Styles
const marginX = "sm:mx-16 mx-6";
const marginY = "sm:my-16 my-6";

// Feature Card Styles
const featureCard = "relative overflow-hidden";

// Transitions/Animations Styles
const pillGrow = "animate-pillGrow";
const metalSheen = "metal-sheen-border relative";
const blink = "TypeAnimation__cursor";

const transitions = {
  pillGrow,
  metalSheen,
  blink,
};

const styles = {
  boxWidth,
  heroWidth,
  heading2,
  paragraph,
  flexCenter,
  flexStart,
  paddingX,
  paddingY,
  padding,
  marginX,
  marginY,
  featureCard,
  ...transitions
};

const layout = {
  section: `flex md:flex-row flex-col ${paddingY}`,
  sectionReverse: `flex md:flex-row flex-col-reverse ${paddingY}`,
  sectionImgReverse: `flex-1 flex ${flexCenter} md:mr-10 mr-0 md:mt-0 mt-10 relative`,
  sectionImg: `flex-1 flex ${flexCenter} md:ml-10 ml-0 md:mt-0 mt-10 relative`,
  sectionInfo: `flex-1 ${flexStart} flex-col`,
};

export { layout };
export default styles;
