import React, { useState } from 'react';
import { phoenixvalorant } from '../../assets';
import { valorantFAQContent } from '../../constants'; // Import the constants

const ValorantFAQ = () => {
    const [isOpen, setIsOpen] = useState(null);

    const toggleFAQ = (id) => {
        setIsOpen(isOpen === id ? null : id);
    };

    return (
        <div className="faq-container relative p-4 rounded-lg shadow-md" style={{ height: '750px' }}>
            <img 
                src={phoenixvalorant} 
                alt="Phoenix Valorant" 
                className="absolute" 
                style={{ zIndex: 10, left: '15%', top: '0', transform: 'translate(-50%, 0%)' }} 
            />

            <div className="w-3/6" style={{ marginLeft: '45%', marginTop: '150px' }}>
                <div className="valorant-faq-box relative">
                    <div className="text-content-container">
                        {/* Map through each FAQ and display it */}
                        {valorantFAQContent.faqs.map((faq) => (
                            <div key={faq.id} className="mb-5 faq-item">
                                <div 
                                    className="flex items-center cursor-pointer"
                                    onClick={() => toggleFAQ(faq.id)}
                                >
                                    <div className={`triangle-icon ${isOpen === faq.id ? 'triangle-icon-rotate' : ''}`}></div>
                                    <span className="ml-2 faq-question">{faq.question}</span>
                                </div>
                                {isOpen === faq.id && <p className="mt-2 text-gray-600 text-sm faq-answer">{faq.answer}</p>}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ValorantFAQ;
