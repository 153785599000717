import axios from 'axios';

export const redirectToStripePortal = async (auth0User) => {
    try {
        const stripeCustomerId = auth0User["https://statseeker.io/stripeCustomerId"];
        if (!stripeCustomerId) {
            console.error('stripeCustomerId not found in user metadata.');
            return;
        }

        const response = await axios({
            method: 'post',
            url: 'https://api.statseeker.io/payments/stripe-portal/',
            data: {
                userId: stripeCustomerId
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status === 200 && response.data.url) {
            window.location.href = response.data.url;
        } else {
            console.error('Failed to get Stripe portal URL from backend. Response:', response.data);
        }
    } catch (error) {
        console.error('Error while fetching Stripe portal URL:', error);
    }
};
