import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import Loading from './Loading';

function Paywall({ children }) {
    const { user, isAuthenticated, getIdTokenClaims } = useAuth0();
    const [isSubscriber, setIsSubscriber] = useState(null); // Initialize with null to represent 'unknown' state

    useEffect(() => {
        if (isAuthenticated) {
            getIdTokenClaims().then(claims => {
                const roles = claims["https://statseeker.io/roles"] || [];
                const userIsSubscriber = roles.includes("subscriber");
                setIsSubscriber(userIsSubscriber);

                if (!userIsSubscriber) {
                    const stripeCustomerId = claims["https://statseeker.io/stripeCustomerId"];
                    if (stripeCustomerId) {
                        fetch(`https://api.statseeker.io/payments/create_checkout_session/?stripeCustomerId=${stripeCustomerId}`)
                            .then(response => response.json())
                            .then(data => {
                                const stripe = window.Stripe('pk_test_51NpZk3AIibRdujIEHJuW4eh6fkNr1OOYnNf1yehDbGAY7BXfbHL4MKDLQ9WOkRnu1VA0Jhwr7AxcI788clzk6NTw00AbKABfil');
                                stripe.redirectToCheckout({ sessionId: data.session_id });
                            })
                            .catch(error => {
                                console.error("Error fetching Stripe session:", error);
                            });
                    } else {
                        console.warn("Stripe customer ID is not available for the user.");
                    }
                }
            });
        } else {
            setIsSubscriber(false); // Set to false if not authenticated
        }
    }, [isAuthenticated, user, getIdTokenClaims]);

    if (isSubscriber === null || isSubscriber === false) {
        return <Loading />; // Display the Loading component while checking or if not a subscriber
    }

    return isSubscriber ? children : null;
}

export default Paywall;
