import { useState, useEffect } from 'react';

function useOnScreen(ref) {
    const [isOnScreen, setOnScreen] = useState(false);
    const [hasBeenOnScreen, setHasBeenOnScreen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (!ref.current || hasBeenOnScreen) {
                return;
            }
            const rect = ref.current.getBoundingClientRect();
            if (rect.top <= window.innerHeight && rect.bottom >= 0) {
                setOnScreen(true);
                setHasBeenOnScreen(true); // set this flag once it's on screen
                window.removeEventListener('scroll', handleScroll); // remove listener once it's been on screen
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [ref, hasBeenOnScreen]);

    return isOnScreen;
}

export default useOnScreen;
